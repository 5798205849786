import React from "react"

import { Hero } from "../../components/blog/hero"
import { PostSidebar } from "../../components/blog/post-sidebar"
import { Breadcrumbs, BreadcrumbsItem } from "../../components/breadcrumbs"
import { Container } from "../../components/container"
import SEO from "../../components/seo"
import img1 from "./images/5-min.jpg"
import styles from "./post-grid.module.css"

const KetoDieta = () => {
  return (
    <div className={styles.wrapper}>
      <SEO
        title={"Opravdu vám keto dieta pomůže zhubnout? | NutritionPro"}
        description={
          "Jistě vám neuniklo, že se ketogenní dieta, zkráceně keto dieta, stále těší velké oblibě. Programy, jejichž součástí je keto stravování, slibují vysoký obsah bílkovin, rychlé hubnutí bez hladovění a cvičení, zdravá a chutná jídla a trvalé výsledky. Můžete také narazit na výroky typu “pozor na zakázaná jídla, ať nepřerušíte hubnutí”. Je keto dieta opravdu zázračný způsob, jak rychle a snadno zhubnout?"
        }
      />
      <Container>
        <Breadcrumbs style={{ margin: "24px 0" }}>
          <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/zdrave-udrzitelne-hubnuti">
            Opravdu vám keto dieta pomůže zhubnout?
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Container>
      <Container>
        <div className={styles.postGrid}>
          <div>
            <Hero
              title="Opravdu vám keto dieta pomůže zhubnout?"
              date="08.02.2023"
            />
            <div>
              <h5 className={styles.postTitle}>Ketogenní dieta</h5>
              <p className={styles.postText}>
                Jistě vám neuniklo, že se ketogenní dieta, zkráceně keto dieta,
                stále těší velké oblibě. Programy, jejichž součástí je keto
                stravování,{" "}
                <b>
                  slibují vysoký obsah bílkovin, rychlé hubnutí bez hladovění a
                  cvičení, zdravá a chutná jídla a trvalé výsledky.
                </b>{" "}
                Můžete také narazit na výroky typu “pozor na zakázaná jídla, ať
                nepřerušíte hubnutí”. Je keto dieta opravdu{" "}
                <b>zázračný způsob, jak rychle a snadno zhubnout?</b>
              </p>
              <img
                src={img1}
                className={styles.postImg}
                style={{ marginBottom: "40px" }}
                alt="img1"
              />
              <h5 className={styles.postTitle}>Co je to keto dieta?</h5>
              <p className={styles.postText}>
                Keto dieta je dieta založena na <b>příjmu tuků</b> (ačkoliv se
                tak mnohdy neprezentuje). Zastoupení makronutrientů v této dietě
                je následující:
              </p>
              <ul className={styles.postList}>
                <li>Sacharidy: {"< 10 %"}</li>
                <li>Bílkoviny: 15 - 25 %</li>
                <li>Tuky: 70 - 80 %</li>
              </ul>
              <p className={styles.postText}>
                Řadí se mezi redukční diety a téměř vůbec se při ní{" "}
                <b>nekonzumují sacharidy.</b> Příjem bílkovin je střední. Cílem
                této diety je dostat organismus do stavu <b>ketózy,</b> což je
                metabolický stav, kdy tělo využívá{" "}
                <b>tuky jako zdroj energie</b> místo sacharidů. Dochází k němu
                při výrazném omezení sacharidů. Omezí se tak příjem glukózy,
                která je hlavním zdrojem energie pro buňky. Místo ní tělo
                využívá <b>ketony,</b> což jsou látky, které se v tomto stavu
                tvoří v těle právě z tuků.
              </p>
              <h5 className={styles.postTitle}>Kdy se keto dieta využívá?</h5>
              <p className={styles.postText}>
                Mnoho z vás keto dietu jistě zná jako{" "}
                <b>běžnou redukční dietu.</b> Má ovšem i{" "}
                <b>terapeutické využití</b> v klinické výživě, což je koneckonců
                to, proč tato dieta vůbec vznikla. Využívá se k léčbě{" "}
                <b>epilepsie u dětí,</b> také při léčbě diabetu, obezity nebo
                porušené inzulínové senzitivity. Může sloužit i osobám, které
                potřebují <b>nutně redukovat hmotnost,</b> ale nemají možnost
                pohybové aktivity, například lidé po operacích. Mohou ji využít
                také <b>sportovci,</b> kteří potřebují zhubnout do určité váhové
                kategorie při sportech, které nejsou závislé na glykogenových
                zásobách, například silový trojboj.
              </p>
              <h5 className={styles.postTitle}>
                Proč vám keto dieta NEpomůže zhubnout?
              </h5>
              <p className={styles.postText}>
                Jak už jsme si vysvětlili, cílem keto stravování je dostat tělo
                do stavu ketózy. To, že se do tohoto stavu dostanete, ale{" "}
                <b>neznamená, že začnete automaticky hubnout.</b> I v ketóze
                můžete tuk nabírat, pokud množství <b>energie převáží výdej.</b>
                Stejně jako u každé redukční diety, i zde jde jen a pouze o{" "}
                <b>kalorický deficit.</b> Pokud se tedy do keto diety pustí
                člověk s nadváhou, sedavým životním stylem a špatně nastaveným
                jídelníčkem, je velmi pravděpodobné, že zhubne, protože bude ve
                velkém kalorickém deficitu. V tomto případě by však fungovala{" "}
                <b>jakákoliv redukční dieta.</b>
              </p>

              <p className={styles.postText}>
                Možná jste zaslechli i to, že se na keto dietě{" "}
                <b>zvyšuje lipolýza,</b> tedy spalování tuků. To je pravda.
                Organismus nepřijímá sacharidy, tělo proto využívá jako
                energetický substrát tuky. Máme tu ovšem 2 podstatná ale. Zaprvé
                se <b>spalování tuků zvyšuje pouze první týdny diety,</b>
                poté už nikoliv. A zadruhé: se zvyšující se lipolýzou jde ruku v
                ruce i <b>zvyšující se lipogeneze, tedy ukládání tuků.</b> O
                tom, zda se tuky budou ukládat nebo spalovat, rozhoduje opět náš
                starý dobrý <b>kalorický deficit.</b>
              </p>

              <p className={styles.postText}>
                Keto dieta a její vztah k hubnutí se zkoumá již více než 40 let.
                Výsledky takových studií ukazují, že lidé dodržující keto dietu
                hubnou <b>stejné množství kilogramů,</b> jako lidé dodržující{" "}
                <b>jiné typy redukčních diet,</b> jako je například low-fat
                dieta (tedy dieta s omezeným množstvím tuků). Důvod hubnutí je
                vždy stejný, a je jím energetický deficit.
              </p>

              <p className={styles.postText}>
                První týdny na keto dietě budete opravdu rychle hubnout.{" "}
                <b>Ztracená kila ovšem netvoří tuk, ale převážně voda.</b>{" "}
                Důvodem je ztráta glykogenu (tedy zásobní formy glukózy) ve
                svalech v důsledku nízkého příjmu sacharidů. Na glykogen se váže
                voda, tím pádem dochází k odvodnění organismu.{" "}
                <b>
                  Nedosáhnete tedy kýženého shazování tuku, ale vody a svalů.
                </b>{" "}
                Pokud se tedy snažíte nabrat svaly nebo vybudovat co největší
                objem, keto dieta už tuplem nebude pro vás.
              </p>

              <p className={styles.postText}>
                <b>TIP:</b> Jak se dostat do formy díky zdravému stravování se
                dozvíte{" "}
                <a
                  href="https://nutritionpro.cz/blog/dostante-se-do-formy/"
                  target="_blank"
                >
                  zde
                </a>
                .
              </p>
              <h5 className={styles.postTitle}>Nevýhody keto diety</h5>
              <p className={styles.postText}>
                Vědecké studie prokázaly, že z dlouhodobého hlediska není
                výhodné keto dietu držet. Potvrdily <b>vyšší mortalitu</b> u
                lidí s nízkým příjmem sacharidů oproti lidem s běžným příjmem,
                tedy okolo 50 % sacharidů v jídelníčku.
              </p>

              <p className={styles.postText}>
                Nadměrný příjem tuků <b>zvyšuje riziko obezity,</b> diabetu
                mellitu 2. typu, hypertenze a dalších kardiovaskulárních
                onemocnění nebo nealkoholické steatózy jater. Důležitý je
                samozřejmě výběr tuků a kvalita dané potraviny.
              </p>

              <p className={styles.postText}>
                Další nevýhodou keto diety je omezený výběr potravin, který může
                vést až k{" "}
                <b>nedostatku určitých vitaminů či minerálních látek.</b>
              </p>
              <h5 className={styles.postTitle}>Výhody keto diety</h5>
              <p className={styles.postText}>
                Ketogenní dieta vám sice <b>zázračně zhubnout nepomůže,</b> u
                některých skupin ovšem <b>určité výhody přináší.</b> Příkladem
                může být léčba diabetických nebo obézních pacientů, kdy může v
                důsledku dodržování keto diety dojít ke{" "}
                <b>zlepšení krevních cukrů a lipidů</b> či upravení hladiny
                inzulinu.
              </p>

              <h5 className={styles.postTitle}>Závěrem</h5>
              <p className={styles.postText}>
                Jak už je nejspíše patrné, principem všech redukčních diet je{" "}
                <b>vytvoření kalorického deficitu.</b> Neexistuje tedy jedna
                nejefektivnější redukční dieta, pouze různé typy, které jsou pro
                každého jinak udržitelné a záleží pouze na vašich preferencích a
                životním stylu. Ideální je se{" "}
                <b>vyvarovat extrémnímu stravování.</b> Důležité jsou{" "}
                <b>udržitelné návyky,</b> protože zhubnout je jen začátek
                úspěchu. Pokud se rozhodnete nějaký čas držet dietu, dbejte na
                to, aby vám přinesla určité návyky a plynule jste z ní mohli
                přejít k <b>běžnému stravování,</b> které bude udržitelné. Také
                pamatujte na bílkoviny, které jsou v jídelníčku klíčové, protože
                vás nejvíce zasytí a činí tak jídelníček udržitelným.
                Udržitelnou cestou plnou bílkovin jsou i{" "}
                <a href="https://nutritionpro.cz/" target="_blank">
                  krabičky NutritionPro.
                </a>{" "}
                Stačí zvolit hubnoucí program a krabičky plné všech potřebných
                živin jsou na cestě. Vyhněte se extrémům a začněte svou cestu,
                která vám pomůže kila shodit, ale i si vysněnou postavu udržet.
                Více o zdravém a udržitelném hubnutí si můžete přečíst v{" "}
                <a
                  href="https://nutritionpro.cz/blog/zdrave-udrzitelne-hubnuti/"
                  target="_blank"
                >
                  tomto článku.
                </a>
              </p>

              <p className={styles.postText}>
                Autorka: Ing. Kristina Heřmanová
              </p>
            </div>
          </div>
          <PostSidebar />
        </div>
      </Container>
    </div>
  )
}

export default KetoDieta
